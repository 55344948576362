/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import { ContentPageLayout } from '@components/Layout';
import { observer } from 'mobx-react';
import { graphql } from 'gatsby';
import { LOST_KEYS } from '../../constants/other-form-fields';
import { FaultReportForm } from '@components/FaultReportForm';
import { Box } from '@primitives';

interface ILostKeysFormPage {
  data: any;
}

const LostKeysFormPage: FC<ILostKeysFormPage> = observer(({
  data: { markdownRemark: { frontmatter: { blocks: texts }, htmlAst } },
}) => {
  return (
    <ContentPageLayout headerTexts={texts[0]} htmlContent={htmlAst}>
      <Box mt="3">
        <FaultReportForm id="lost-keys" fields={LOST_KEYS} values={{ title: 'Tilaa sarjoitus tai lisäavaimia' }} />
      </Box>
    </ContentPageLayout>
  );
});

export const pageQuery = graphql`
query LostKeysFormPageQuery {
  markdownRemark(frontmatter: {path: {eq: "/forms/lost-keys/"}}) {
    frontmatter {
      path
      blocks {
        title
      }
    }
    htmlAst
  }
}`;
export default LostKeysFormPage;
